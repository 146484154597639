var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-participant-messages"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"disable-pagination":"","disable-sort":"","hide-default-footer":"","items-per-page":-1},on:{"click:row":function (item) { return _vm.messageDetails(item); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?[_vm._v(_vm._s(_vm._f("moment")(item.date,"DD-MM-YYYY HH:mm")))]:_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(_vm.getMessageType(item.type)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.sendAgain(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.sendAgain')))])],1)],1)],1)]}}]),model:{value:(_vm.state.selected),callback:function ($$v) {_vm.$set(_vm.state, "selected", $$v)},expression:"state.selected"}}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.state.messageDetails),callback:function ($$v) {_vm.$set(_vm.state, "messageDetails", $$v)},expression:"state.messageDetails"}},[_c('m-participant-message-details',{attrs:{"item":_vm.state.messageDetailsItem},on:{"close-dialog":function($event){_vm.state.messageDetails = false}}})],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.state.sendAgain),callback:function ($$v) {_vm.$set(_vm.state, "sendAgain", $$v)},expression:"state.sendAgain"}},[_c('m-participant-message-send-again',{attrs:{"item":_vm.state.sendAgainItem,"email":_vm.state.participantEmail},on:{"close":function($event){_vm.state.sendAgain = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }