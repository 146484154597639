












































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    MParticipantMessageDetails: () =>
      import(
        "@/components/molecules/participant/m-participant-message-details.vue"
      ),
    MParticipantMessageSendAgain: () =>
      import(
        "@/components/molecules/participant/m-participant-message-send-again.vue"
      ),
  },
  setup(_, { root }) {
    const { getMessageType } = useMisc({ root });

    const state = reactive({
      expanded: [],
      headers: [
        { value: "subject", text: root.$tc("layout.misc.name") },
        {
          value: "date",
          text: root.$tc("layout.misc.sendDate"),
          align: "center",
        },
        { value: "type", text: root.$tc("layout.misc.type"), align: "center" },
        { value: "actions", text: "", align: "right", width: 1 },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      messageDetails: false,
      messageDetailsItem: [],
      sendAgain: false,
      sendAgainItem: [],
      participantEmail: "",
    });

    const messageDetails = (item: any) => {
      state.messageDetailsItem = Object.assign({}, item);
      state.messageDetails = true;
    };

    const sendAgain = (item: any) => {
      state.sendAgainItem = Object.assign({}, item);
      state.sendAgain = true;
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`mail-checklist/participant/${root.$route.params.ptid}`)
        .then(({ data }) => {
          state.items = data;
          state.total = data.length;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const fetchParticipant = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${root.$route.params.ptid}`)
        .then(({ data: { participant } }) => {
          state.participantEmail = participant.email;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchParticipant);

    return {
      state,
      fetchData,
      messageDetails,
      sendAgain,
      getMessageType,
    };
  },
});
